jQuery(document).ready(function($){
    $(window).load(function() {
        if($('#product-video').length) {
            var videoURL = $('#product-video').data('video');
                videoThumb = $('#product-video').data('thumb');
                videoPosition = $('#product-video').data('order');
                videoActive = '';

            // If set to primary, set active class
            if(videoPosition == 'primary') {
                var videoActive = ' active';
            } 

            $('.flex-viewport').prepend('<div class="gd__product-gallery-video ' + videoActive + '"><video controls controlsList="nodownload"><source src="' + videoURL + '" type="video/mp4"></video></div>');

            $('.flex-control-nav.flex-control-thumbs').prepend('<li class="gd__product-gallery-video-open' + videoActive + '"><img src="' + videoThumb + '" draggable="false"></li>');
        }
    });

    $('.flex-control-nav').on('click', '.gd__product-gallery-video-open', function() {
        $('.gd__product-gallery-video').addClass('active');
        $('.gd__product-gallery-video video').trigger('play');
        $('.gd__product-gallery-video-open').addClass('active');
    });

    $('.flex-control-nav li').click(function() {
        if(!$(this).hasClass('gd__product-gallery-video')) {
            $('.gd__product-gallery-video video').trigger('pause');
            $('.gd__product-gallery-video').removeClass('active');
        $('.gd__product-gallery-video-open').removeClass('active');
        }
    });
});