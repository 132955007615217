jQuery(document).ready(function($) {
    
	$('.wc__sidebar-open').click(function(e) {
		e.preventDefault();
		$('.wc__overlay').show();
		var sidebar = $(this).data('sidebar');
		$('#wc__sidebar-' + sidebar).addClass('active');
	});

	$('.wc__overlay').click(function() {
		$('.wc__overlay').hide();
		$('.wc__sidebar.active').removeClass('active');
	});

	$('.wc__sidebar-close').click(function() {
		$('.wc__overlay').hide();
		$('.wc__sidebar.active').removeClass('active');
	});

	if($('body.single-product .woocommerce-notices-wrapper .wc-forward').length) {
		$('.wc__overlay').show();
		$('#wc__sidebar-cart').addClass('active');
		$('.single_add_to_cart_button').html('<span class="fa fa-check"></span> Added to basket');
		$('.cart').append('<a href="/cart" class="btn btn--black d-lg-none mobile_cart_btn">View basket and checkout</a>');
		
	}	

 	
});