jQuery(document).ready(function($) {
    
    $(window).load(function() {
    	$('.alm-filter--toggle').attr('aria-expanded', 'false');
    	$('.alm-filter--inner').attr('aria-hidden', 'true');
    });

    $('.yith-woo-ajax-navigation .widgettitle').click(function() {
        $parent = $(this).parent('.yith-woo-ajax-navigation');
        $parent.toggleClass('active');
        $('.yith-wcan-list', $parent).slideToggle();
    });
 
});