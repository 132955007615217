jQuery(document).ready(function($) {
    
    // Registration Password
    $('.form-validation').validate({
	  	rules: {
	    	password1: 'required',
	    	password2: {
	      		equalTo: "#password1"
	    	}
	  	}
	});

    // Registration upload
	$('.form__upload-field').change(function() {
  		var file = $(this)[0].files[0].name;
  			$parent = $(this).parents('.form__upload');

  		$('.form__upload-filename-name', $parent).text(file);
  		$parent.addClass('active');
	});
	
	// Registration Recaptcha		    
    $('#register-form').submit(function(event) {
        event.preventDefault();
 		
 		if($('#register-form').valid()) {
	        grecaptcha.ready(function() {
	            grecaptcha.execute('6Lf2Me0ZAAAAAC1Rg1WPDvt_PqfwsMKTNw3uhTD5', {action: 'registration_submit'}).then(function(token) {
	                $('#register-form').prepend('<input type="hidden" name="token" value="' + token + '">');
	                $('#register-form').prepend('<input type="hidden" name="action" value="registration_submit">');
	                $('#register-form').unbind('submit').submit();
	            });;
	        }); 			
 		}
  	});
 
});