jQuery(document).ready(function($) {
    
	$('.sidebar__button').click(function() {
		$('#sidebar').slideToggle();
		$(this).toggleClass('active');
	});

	// $('.widgettitle').click(function() {
	// 	console.log(true);
	// 	$(this).toggleClass('active').next().slideToggle();
	// });
 
});
