jQuery(document).ready(function($) {
    
	$('.js-hero').slick({
		autoplay: true,
		autoplaySpeed: 5000,
		dots: true,
		arrows: false,
		pauseOnHover: false
	});

	$('.js-hero #slick-slide00 .hero__slide-content').addClass('active');

	$('.js-hero').on('afterChange', function(event, slick, currentSlide, nextSlide){
		$('.hero__slide-content.active').removeClass('active');
  		$('#slick-slide0' + currentSlide + ' .hero__slide-content').addClass('active');
	});
    
	$('.js-logos').slick({
		autoplay: true,
		autoplayspeed: 2000,
		slidesToShow: 6,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		pauseOnHover: false,
		infinite: true,
		responsive: [
		    {
		      breakpoint: 991,
		      settings: {
		        slidesToShow: 3
		      }
		    },
		    {
		      breakpoint: 500,
		      settings: {
		        slidesToShow: 2
		      }
		    }
		]
	});

	$('.js-productcarousel').slick({
		autoplay: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		arrows: true,
		dots: false,
		responsive: [
		    {
		      breakpoint: 991,
		      settings: {
		        slidesToShow: 2
		      }
		    },
		    {
		      breakpoint: 500,
		      settings: {
		        slidesToShow: 1,
		        dots: true,
		        arrows: false
		      }
		    }
		]
	});

	$('.js-testimonialcarousel').slick({
		autoplay: false,
		arrows: true,
		dots: false
	});


    $('.js-brand-carousel').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        centerMode: true,
		responsive: [
		    {
		      breakpoint: 991,
		      settings: {
		        slidesToShow: 2,
		        arrows: false
		      }
		    },
		    {
		      breakpoint: 500,
		      settings: {
		        slidesToShow: 1,
		        arrows: false
		      }
		    }
		]
    });

    $(window).on('load resize orientationchange', function() {
        $('.js-blocks').each(function(){
            var $carousel = $(this);
            if ($(window).width() > 992) {
                if ($carousel.hasClass('slick-initialized')) {
                    $carousel.slick('unslick');
                }
            }
            else{
                if (!$carousel.hasClass('slick-initialized')) {
                    $carousel.slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        mobileFirst: true,
                        arrows: false,
                        dots: false,
                        centerMode: true
                    });
                }
            }
        });
    });
 
});
