jQuery(document).ready(function($) {
    
	$('.header__nav-button').click(function(e) {
		e.preventDefault();
		$(this).toggleClass('header__nav-button--active');
		$nav.stop().toggleClass('header__nav--active').toggle();
	});

	if($(window).width() < 992) {
		$('#header__nav li.menu-item-has-children > a').click(function(e) {
			e.preventDefault();
			$(this).next().slideToggle();
		});
	}
 
});
